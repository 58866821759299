<template>
    <div v-if="loading">
        <!--------------------- loading --------------------->
        <div v-if="!error" key="loading" class="text-center loading-container">
        <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
        <p class="p-text-s mt-3">Loading...</p>
        </div>
        <!--------------------- error --------------------->
        <div v-if="error" key="error" class="jcard-content text-center">
        <h5 class="mb-3">
            <i class="fad fa-exclamation-circle text-blue fa-3x"></i><br />
        </h5>
        <p class="p-text-s">
            Unable to load data. Please check your internet connection.
        </p>
        </div>
    </div>
</template>



<script>

export default {
    name: 'LoadingComponent',
    data() {
        return {
            loading: true,
            error: false,
        };
    },
    mounted() {
        this.$root.$on('loadingDone', (errorMsg) => {
            if (errorMsg) {
                this.error = true;
                console.log('Loading error: ' + errorMsg);
            } else {
                this.loading = false;
                this.error = false;
            }
        });
    },
    
    methods: {
    },
};


</script>

<style scoped></style>
